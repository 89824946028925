import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import { DataClassificationType } from '../../../helpers/constants/entities/vectorAnalysisMap';
import TextField from '../../TextField';
import ComboBox from '../../ComboBox';
import Button from '../../Button';
import {
  MIN_POLYGON_AREA_MIN,
  MIN_POLYGON_AREA_MAX,
} from '../../../helpers/constants/areaLimits';
import LinkedZonesControls from '../LinkedZonesControls';
import UseInterpolationCheckbox from '../UseInterpolationCheckbox';

import './index.scss';

const getDataClassificationTypeOptions = () => {
  return [
    {
      title: i18n.t('zones-ops.common.data-classification-types.natural-breaks'),
      value: DataClassificationType.naturalBreaks,
    },
    {
      title: i18n.t('zones-ops.common.data-classification-types.equal-interval'),
      value: DataClassificationType.equalInterval,
    },
    {
      title: i18n.t('zones-ops.common.data-classification-types.equal-count'),
      value: DataClassificationType.equalCount,
    },
    {
      title: i18n.t('zones-ops.common.data-classification-types.spatially-localized-balanced'),
      value: DataClassificationType.spatiallyLocalizedBalanced,
    },
    {
      title: i18n.t('zones-ops.common.data-classification-types.spatially-localized-spatial'),
      value: DataClassificationType.spatiallyLocalizedSpatial,
    },
    {
      title: i18n.t('zones-ops.common.data-classification-types.spatially-localized-value'),
      value: DataClassificationType.spatiallyLocalizedValue,
    },
  ];
};

const Panel = ({
  t,
  name,
  areaUnit,
  fieldArea,
  polygonMinArea,
  numberOfZones,
  dataClassificationType,
  runAnalysisDisabled,
  useInterpolatedData,
  onPropChange = () => {},
  onRunAnalysis = () => {},
}) => {
  const dataClassificationTypeOptions = getDataClassificationTypeOptions();
  const dataClassificationTypeOption = dataClassificationTypeOptions.find((option) => {
    return option.value === dataClassificationType;
  });

  const handleNumberOfZonesChange = useCallback((value) => {
    onPropChange('numberOfZones', value);
  }, [onPropChange]);

  return (
    <div className="ops-creation-panel">
      <TextField
        required
        value={name}
        className="ops-creation-panel__title"
        title={t('zones-ops.common.title')}
        onChange={(e) => onPropChange('name', e.target.value)}
      />
      <ComboBox
        classes={{
          root: 'ops-creation-panel__select',
        }}
        disableClearable
        disableCloseOnSelect={false}
        title={t('zones-ops.common.data-classification-type')}
        options={dataClassificationTypeOptions}
        value={dataClassificationTypeOption}
        getOptionSelected={(option, value) => option.value === value?.value}
        onChange={(_event, { value }) => onPropChange('dataClassificationType', value)}
      />
      <LinkedZonesControls
        areaUnit={areaUnit}
        fieldArea={fieldArea}
        numberOfZones={numberOfZones}
        dataClassificationType={dataClassificationType}
        onNumberOfZonesChange={handleNumberOfZonesChange}
      />
      <TextField
        title={t('zones-ops.common.min-polygon-area')}
        type="number"
        value={polygonMinArea}
        onChange={(e) => onPropChange('polygonMinArea', parseInt(e.target.value, 10))}
        InputProps={{
          inputProps: {
            min: MIN_POLYGON_AREA_MIN,
            max: MIN_POLYGON_AREA_MAX,
          },
        }}
      />
      <UseInterpolationCheckbox
        checked={useInterpolatedData}
        onChange={(value) => onPropChange('useInterpolatedData', value)}
      />
      <Button
        disabled={runAnalysisDisabled}
        className="ops-creation-panel__run-btn"
        variant="contained"
        color="primary"
        onClick={onRunAnalysis}
      >
        {t('zones-ops.common.run-analysis')}
      </Button>
    </div>
  );
};

export default withTranslation()(Panel);
