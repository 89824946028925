import React from 'react';

import { Typography } from '@material-ui/core';
import AreaLimitMessage from '../../../../../containers/AreaLimitMessage';

import './index.scss';

export default function AreaLimitPopupPanel() {
  return (
    <div className="area-limit-popup-panel">
      <Typography>
        <AreaLimitMessage />
      </Typography>
    </div>
  );
}
