import i18n from 'i18next';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DeleteIcon from '@material-ui/icons/Delete';

import { AreaUnit } from '../../../../user/helpers/constants/user';
import type { DefaultFieldTableRow } from '../../../fieldsList/types/tableRow';
import type { TableColumn } from '../../../tableView/types/column';
import type { RowMenuItem } from '../../../tableView/types/row';
import { getAreaUnitLabel } from '../../../../../helpers';
import RenameIcon from '../../../../../components/Icons/rename';
import { FieldOperation } from '../../../fieldWorkflow/helpers/constants/operation';

const getRowMenuItems = (): RowMenuItem[] => {
  return [
    {
      id: FieldOperation.renameField,
      label: i18n.t('general.controls.rename'),
      IconComponent: RenameIcon,
    },
    {
      id: FieldOperation.deleteField,
      label: i18n.t('general.controls.delete'),
      IconComponent: DeleteIcon,
    },
  ];
};

export const getBaseTableColumns = ({
  areaUnit,
  onStopNameEdit = () => {},
  onFieldLinkClick = () => {},
  ...args
}: {
  selectable: false,
  areaUnit: AreaUnit,
  onStopNameEdit?: (r: DefaultFieldTableRow, v: string) => void,
  onFieldLinkClick?: () => void,
} | {
  selectable: true,
  areaUnit: AreaUnit,
  rowsSelection: number,
  onHeadCheckboxClick: (s: boolean) => void,
  onRowCheckboxClick: (r: DefaultFieldTableRow) => void,
  onStopNameEdit?: (r: DefaultFieldTableRow, v: string) => void,
  onFieldLinkClick?: () => void,
}): TableColumn<DefaultFieldTableRow>[] => {
  const columns: TableColumn<DefaultFieldTableRow>[] = [
    {
      id: 'name',
      label: i18n.t('field-profiler.fields-list.columns.field-name'),
      element: 'editable-link',
      leading: true,
      rowSpan: 2,
      onStopEdit: onStopNameEdit,
      onLinkClick: onFieldLinkClick,
    },
    {
      id: 'farmName',
      label: i18n.t('general.shared.farm'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'dateUpdated',
      label: i18n.t('field-profiler.fields-list.columns.date-updated'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'dateCreated',
      label: i18n.t('field-profiler.fields-list.columns.date-created'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'area',
      label: getAreaUnitLabel(areaUnit),
      element: 'default',
      secondary: true,
    },
    {
      id: 'ownTypeLabel',
      label: i18n.t('field-profiler.fields-list.columns.own-type'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'preview',
      label: i18n.t('general.shared.preview'),
      element: 'image',
    },
  ];

  return args.selectable
    ? [
      {
        id: 'rowSelect',
        labelElement: 'checkbox',
        handler: args.onHeadCheckboxClick,
        value: args.rowsSelection,
        element: 'checkbox',
        rowSpan: 2,
        onCheckboxClick: args.onRowCheckboxClick,
      },
      ...columns,
    ]
    : columns;
};

export const getTableColumns = ({
  areaUnit,
  editMode,
  rowsSelection,
  onHeadCheckboxClick,
  onMenuItemClick,
  openManageLabelsPopup,
  onRowCheckboxClick,
  onRowDeleteClick,
  onStopNameEdit,
  onFieldLinkClick,
}: {
  areaUnit: AreaUnit,
  editMode: boolean,
  rowsSelection: number,
  onHeadCheckboxClick: (s: boolean) => void,
  onMenuItemClick: (i: RowMenuItem, r: DefaultFieldTableRow) => void,
  openManageLabelsPopup: (r: DefaultFieldTableRow) => void,
  onRowCheckboxClick: (r: DefaultFieldTableRow) => void,
  onRowDeleteClick: (r: DefaultFieldTableRow) => void,
  onStopNameEdit: (r: DefaultFieldTableRow, v: string) => void,
  onFieldLinkClick: () => void,
}): TableColumn<DefaultFieldTableRow>[] => {
  const baseColumns = getBaseTableColumns({
    selectable: editMode,
    areaUnit,
    rowsSelection,
    onHeadCheckboxClick,
    onRowCheckboxClick,
    onStopNameEdit,
    onFieldLinkClick,
  });

  return editMode
    ? [
      ...baseColumns,
      {
        id: 'action',
        label: '',
        element: 'icon-button',
        iconComponent: DeleteSharpIcon,
        onButtonClick: onRowDeleteClick,
      },
    ]
    : [
      ...baseColumns,
      {
        id: 'manageLabels',
        label: '',
        element: 'icon-button',
        horizontalAlign: 'right',
        tooltip: i18n.t('field-profiler.fields-list.cells.manage-labels'),
        iconComponent: LocalOfferIcon,
        onButtonClick: openManageLabelsPopup,
      },
      {
        id: 'action',
        label: '',
        element: 'menu',
        menuItems: getRowMenuItems(),
        onMenuItemClick,
      },
    ];
};
