import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { closeSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';

import TextField from '../../../../../components/TextField';
import Label from '../../../../../components/Label';
import Button from '../../../../../components/Button';
import Link from '../../../../../components/Link';
import ToolsPanel from '../../../../../components/ToolsPanel';
import {
  errorNotify,
  successNotify,
  warningNotify,
} from '../../../../notifications/helpers/functions/notify';
import {
  removeLabel,
  setLabels,
  setName,
  fetchFarmBoundaries,
  saveField,
} from '../../createFieldSlice';
import { openPopup } from '../../../popups/popupsSlice';
import {
  getFieldLink,
  getRootLink,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import { isFeatureTypesCorrect } from '../../../../field/helpers/functions/features';
import {
  selectLabels,
  selectName,
  selectFarm,
  selectDrawnFeature,
  selectDrawnError,
} from '../../createFieldSelectors';
import useSingleFarmFromList from '../../../../farms/hooks/useSingleFarmFromList';
import { selectReachedAreaLimit } from '../../../../user/userSelectors';
import FarmSelect from '../../../../farms/containers/FarmSelect';
import { TransformedFarm } from '../../../../farms/types/farm';
import { Label as ILabel } from '../../../../field/types/field';

import './index.scss';

export default function CreateFieldToolsPanel() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const fieldFarm = useSelector(selectFarm);
  const { farm } = useSingleFarmFromList({
    farmUuid: fieldFarm?.uuid,
  });
  const labels = useSelector(selectLabels);
  const reachedAreaLimit = useSelector(selectReachedAreaLimit);
  const feature = useSelector(selectDrawnFeature);
  const name = useSelector(selectName);
  const drawnError = useSelector(selectDrawnError);
  const trimmedName = name.trim();
  const saveButtonDisabled = reachedAreaLimit || !trimmedName || !farm || !feature || drawnError;

  const handleCancel = () => {
    if (name || farm || feature.features.length || labels.length) {
      dispatch(openPopup({
        type: 'unsaved-changes',
        titleLabel: t('general.popups.quit-creation.title'),
        cancelLabel: t('general.popups.quit-creation.keep-creating'),
        onConfirm: () => {
          history.push(getRootLink(PAGES_ROOTS.fields));
        },
      }));
    } else {
      history.push(getRootLink(PAGES_ROOTS.fields));
    }
  };

  const handleSave = () => {
    const featureTypesCorrect = isFeatureTypesCorrect(feature.features);

    if (!featureTypesCorrect) {
      warningNotify({
        message: t('general.notifications.oops-draw-field'),
      });

      return;
    }

    // @ts-expect-error
    dispatch(saveField(feature.features))
    // @ts-expect-error
      .then((result) => {
        const { error, data } = result.payload;

        if (error) {
          errorNotify({ error, dispatch });
        } else {
          const notificationId = successNotify({
            message: t('create-field.notifications.field-saved', { name: data.name }),
            actions: [
              {
                title: t('general.controls.go-to-field'),
                handler: () => {
                  if (farm?.uuid) {
                    history.push(getFieldLink(farm.uuid, data.uuid));
                    closeSnackbar(notificationId);
                  }
                },
              },
            ],
          });
        }
      });
  };

  const handleFieldNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setName(event.target.value));
  };

  const handleFarmChange = (newFarm: TransformedFarm | null) => {
    if (newFarm?.uuid) {
      // @ts-expect-error
      dispatch(fetchFarmBoundaries(newFarm.uuid));
    }
  };

  const handleAddLabelClick = () => {
    dispatch(openPopup({
      type: 'manage-labels',
      fieldLabels: labels,
      onConfirm: (newLabels: ILabel[]) => {
        dispatch(setLabels(newLabels));
      },
    }));
  };

  const handleLabelDelete = (label: ILabel) => {
    dispatch(removeLabel(label));
  };

  return (
    <ToolsPanel
      classes={{
        root: 'create-field-tools-panel',
        actions: 'actions',
        actionsLeft: 'actions__left',
        actionsRight: 'actions__right',
        filters: 'filters',
      }}
      actionsLeft={!reachedAreaLimit
        && (
          <p className="description">
            <Trans i18nKey="create-field.description">
              text <Link to={getRootLink(PAGES_ROOTS.upload)}>text</Link>
            </Trans>
          </p>
        )}
      actionsRight={(
        <>
          <Button
            variant="outlined"
            onClick={handleCancel}
          >
            {t('general.controls.cancel')}
          </Button>
          <Button
            disabled={saveButtonDisabled}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {t('general.controls.save')}
          </Button>
        </>
      )}
      filtersLeft={(
        <div className="params-panel">
          <TextField
            placeholder={t('create-field.draw.enter-field-name')}
            value={name}
            title={(
              <>
                {t('field-profiler.fields-list.columns.field-name')}
                <span className="required-asterisk"> *</span>
              </>
            )}
            onChange={handleFieldNameChange}
          />
          <FarmSelect
            required
            withCreateFarm
            selectedFarmUuid={farm?.uuid}
            onFarmChange={handleFarmChange}
          />
          <div>
            <label
              htmlFor="labelsContainer"
              className="labels__label"
            >
              {t('general.controls.map-legend.labels')}
            </label>
            <div
              id="labelsContainer"
              className="labels__container"
            >
              {
                // @ts-expect-error
                labels.map((label, index) => {
                  return (
                    <Label
                      key={index}
                      text={`${label.name}: ${label.value}`}
                      deletable={true}
                      onDelete={() => handleLabelDelete(label)}
                    />
                  );
                })
              }
              <Button
                className="add-button"
                size="small"
                startIcon={<AddIcon className="add-button__icon" />}
                onClick={handleAddLabelClick}
              >
                {t('field-profiler.fields-list.cells.add-label')}
              </Button>
            </div>
          </div>
        </div>
      )}
    />
  );
}
