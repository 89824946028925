import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { getCellClasses } from '../../../helpers/functions/cell';
import EditMode from './EditMode';
import ReadMode from './ReadMode';

import './index.scss';

const EditableLinkCell = ({
  headCell = {},
  editMode = false,
  row = {},
}) => {
  const providedValue = row[headCell.id];

  return (
    <TableCell
      rowSpan={row.hasNestedRow && headCell.rowSpan ? headCell.rowSpan : undefined}
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('editable-cell', headCell, row)}
    >
      {
        editMode
          ? (
            <EditMode
              value={providedValue.title}
              onFinish={(value) => headCell.onStopEdit(row, value)}
            />
          )
          : (
            <ReadMode
              value={providedValue}
              onLinkClick={headCell.onLinkClick}
            />
          )
      }
    </TableCell>
  );
};

export default EditableLinkCell;
