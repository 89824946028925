import React, {
  ChangeEvent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import { openPopup } from '../../../ui/popups/popupsSlice';
import { useGetFarmsQuery } from '../../farmsAPI';
import ComboBox, { Option } from '../../../../components/ComboBox';
import { TransformedFarm } from '../../types/farm';

import './index.scss';

const getCreateFarmProps = (farmName: string, onCreateClick: () => void) => {
  return {
    actions: [
      {
        icon: (
          <AddIcon fontSize="small" className="icon" />
        ),
        title: farmName
          ? i18n.t('general.controls.create-new-farm', { farmName })
          : i18n.t('general.controls.create-new'),
      },
    ],
    renderAction: (action: { icon?: ReactNode, title?: string }) => {
      return (
        <div className="farm-select__action">
          {action.icon}
          <span>{action.title}</span>
        </div>
      );
    },
    onActionClick: onCreateClick,
  };
};

const filterOptions = (options: Option<TransformedFarm>[], query: string) => {
  return options.filter((option) => {
    return option.title.toLowerCase().includes(query.toLowerCase());
  });
};

export default function FarmSelect({
  required,
  withCreateFarm,
  selectedFarmUuid,
  className,
  loading,
  disabled,
  onFarmChange,
}: {
  required?: boolean,
  withCreateFarm?: boolean,
  selectedFarmUuid?: string,
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onFarmChange: (v: TransformedFarm | null) => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: farms = [],
    isFetching,
  } = useGetFarmsQuery();
  const [searchedFarmName, setSearchedFarmName] = useState('');

  const handleCreateFarm = () => {
    dispatch(openPopup({
      type: 'create-farm',
      farmName: searchedFarmName,
      onFarmCreated: onFarmChange,
    }));
  };

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedFarmName(e.target.value);
  };

  const farmOptions = useMemo(
    () => {
      return farms.map((item) => {
        return {
          title: item.name || '',
          value: item,
        };
      });
    },
    [farms],
  );

  const selectedFarm = useMemo(
    () => {
      const farm = farms.find(({ uuid }) => uuid === selectedFarmUuid);

      return farm
        ? {
          title: farm?.name || '',
          value: farm,
        }
        : null;
    },
    [selectedFarmUuid, farms],
  );

  return (
    <ComboBox
      data-test="farm-select"
      required={required}
      title={t('general.shared.farm')}
      placeholder={
        withCreateFarm
          ? t('general.controls.select-or-create')
          : t('general.controls.select')
      }
      loading={isFetching || loading}
      disabled={disabled}
      value={selectedFarm}
      options={farmOptions}
      disableCloseOnSelect={false}
      getOptionSelected={(option, value) => option.value.uuid === value.value.uuid}
      filterOptions={(options) => filterOptions(options, searchedFarmName || selectedFarm?.title || '')}
      onChange={(_event: React.ChangeEvent<{}>, selectedOption) => {
        onFarmChange(selectedOption?.value ?? null);
      }}
      onSearchValueChange={handleSearchValueChange}
      classes={{
        root: clsx('farm-select', className),
      }}
      {...(withCreateFarm ? getCreateFarmProps(searchedFarmName, handleCreateFarm) : {})}
    />
  );
}
